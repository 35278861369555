import axios from 'axios'
import { environment } from '@/data/environment/'

export default {
  namespaced: true,
  state: {
    error: null,
    listInvoice: [],
    dataBase64: '',
    responseReprocess: [],
    responseUnlockInvoice: [],
    responseEmail: null,
    runListInvoice: false
  },
  getters: {},
  mutations: {

    setListInvoice (state, data) {
      state.listInvoice = data
      state.error = null
    },
    setDataPDFBase64 (state, data) {
      state.dataBase64 = data
      state.error = null
    },
    setResponseReprocess (state, data) {
      state.responseReprocess = data
      state.error = null
    },
    setResponseUnlockInvoice (state, data) {
      state.responseUnlockInvoice = data
    },
    setResponseEmail (state, data) {
      state.responseEmail = data
      state.error = null
    },
    setRunListInvoice (state, data) {
      state.runListInvoice = data
    },

  },
  actions: {
    getListInvoice ({ commit }, data) {
      let params = 'IdCompania=' + JSON.parse(atob(localStorage.getItem('uid'))).idcompania

      if (!data) {
        let dateCurrent = new Date()
        dateCurrent = dateCurrent.getFullYear() + '-' + String(dateCurrent.getMonth() + 1).padStart(2, '0') + '-' + String(dateCurrent.getDate()).padStart(2, '0')
        params += `&FechaInicial= ${dateCurrent}`
        params += `&FechaFinal= ${dateCurrent}`
      } else {
        if (data.ProveedorTecnologico) {
          params += `&ProveedorTecnologico=${data.ProveedorTecnologico}`
        }
        if (data.Consecutivo) {
          params += `&ConsecutivoDocumento= ${data.Consecutivo}`
        }
        if (data.Status && data.Status !== '') {
          params += `&Estado= ${data.Status}`
        }
        if (data.Prefix) {
          params += `&Prefijo= ${data.Prefix}`
        }
        if (data.origin) {
          params += `&OriginTransacion= ${data.origin}`
        }
        if (data.dates) {
          params += `&FechaInicial= ${data.dates[0]}`
          params += `&FechaFinal= ${data.dates[1]}`
        }
      }
      const endpoint = '/api/getInvoices?'
      const url = environment.HIOPOSWEBSERVICES + endpoint + params
      axios
        .create()
        .get(url)
        .then(response => {
          commit('setListInvoice', response.data)
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    reprocessInvoice ({ commit }, data) {
      let params = ''
      params += `consecutivoDocumento=${data.CosecutivoDocumento}`
      params += `&idCompania=${data.IdCompania}`
      params += `&PlantillaPdfHKA=co-default-21-propina-40`

      const endpoint = '/api/sincronizarFEPendientes?'
      const url = environment.HIOPOSWEBSERVICES + endpoint + params
      axios
        .create()
        .get(url)
        .then(response => {
          commit('setResponseReprocess', response.data.respuesta)
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    unlockInvoice ({ commit }, data) {
      let params = ''
      params += `consecutivoDocumento=${data.CosecutivoDocumento}`
      params += `&idCompania=${data.IdCompania}`
      params += `&idFactura=${data.IdFactura}`
      params += `&usuario=${JSON.parse(atob(localStorage.getItem('uid'))).user}`

      const endpoint = '/api/desbloquearDocumento?'
      const url = environment.HIOPOSWEBSERVICES + endpoint + params
      axios
        .create()
        .get(url)
        .then(response => {
          commit('setResponseUnlockInvoice', response.data.respuesta)
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    getDataPDF ({ commit }, data) {
      let params = ''
      params += `consecutivoDocumento= ${data.CosecutivoDocumento}`
      params += `&idCompania= ${data.IdCompania}`

      const endpoint = '/api/descargarPDF?'
      const url = environment.HIOPOSWEBSERVICES + endpoint + params

      axios
        .create()
        .get(url)
        .then(response => {
          commit('setDataPDFBase64', response.data.respuesta)
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    sendEmail ({ commit }, data) {
      let params = ''
      params += `consecutivoDocumento= ${data.CosecutivoDocumento}`
      params += `&idCompania= ${data.IdCompania}`
      params += `&email= ${data.email}`

      const endpoint = '/api/enviarCorreo?'
      const url = environment.HIOPOSWEBSERVICES + endpoint + params

      axios
        .create()
        .get(url)
        .then(response => {
            commit('setResponseEmail', response.data.respuesta)
        })
        .catch(function (error) {
          commit('setResponseEmail', null)
          console.log(error.message)
        })
    },

    setResponseEmail ({ commit }, data) {
      commit('setResponseEmail', data)
    },
    setResponseUnlockInvoiceData ({ commit }, data) {
      commit('setResponseUnlockInvoice', data)
    },
    setRunListInvoiceData ({ commit }, data) {
      commit('setRunListInvoice', data)
    },

  },
}
